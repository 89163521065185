import React from "react";
import MainBlog from "../components/MainBlog";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/BlogFormat.css";
import FooterBlog from "../components/FooterBlog";
import { Helmet } from "react-helmet";

function TarifaPVPC() {
    const title = "Tarifa PVPC 2024: Cambios, Medidas y Consejos para Optimizar tu Factura de Luz";
    const description = "Descubre las novedades y medidas que afectan a la tarifa PVPC en 2024. Desde cambios en impuestos hasta la nueva metodología de cálculo, conoce cómo anticiparte a las fluctuaciones del mercado eléctrico. Consejos para ahorrar y optimizar tu factura de luz. Más en LuzPorHoras.es.";
    const keywords = "Tarifa PVPC 2024, cambios en la factura de la luz, medidas para optimizar la factura, impuestos eléctricos, mercado eléctrico, metodología de cálculo PVPC, consejos para ahorrar energía, LuzPorHoras.es";
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header />
            <div className="main_container">
                <div className="main_content">
                    <div className="header_content">
                        <h1 className="header_title">Tarifa PVPC: Precio Voluntario al Pequeño Consumidor</h1>
                        <MainBlog time={5} />
                    </div>
                    <div className="line_space" />
                    <div className="blog_content">


                        <h2>Medidas para la factura de la luz y el gas en 2024</h2>
                        <ul>
                            <li>Finaliza la aplicación del tope del gas, medida que aplicaba a consumidores de la tarifa PVPC y los usuarios peninsulares de mercado libre.</li>
                            <li>Sube el IVA de la luz al 10% durante todo el 2024 si se tiene contratado menos de 10 kW de potencia.</li>
                            <li>Sube el IVA del gas al 10% durante los primeros tres meses de 2024. A partir del 1 de abril de 2024, este impuesto ascenderá al 21%.</li>
                            <li>Asciende el Impuesto Especial de Electricidad (IEE) al 2.5 % durante los primeros tres meses de 2024 y, a partir de entonces, subirá al 3.8% hasta el 30 de junio de 2024.</li>
                            <li>Se prorrogan los descuentos temporales del bono social hasta el 30 de junio de 2024.</li>
                            <li>Se prorroga hasta el 30 de junio de 2024 la TUR 4, quedan excluidos de la prórroga anterior aquellos consumidores que a 1 de octubre de 2023 no hubieran instalado los repartidores de costes o contadores individuales de calefacción y que tampoco hubieran enviado la declaración responsable.</li>
                        </ul>

                        <img src="https://tarifaluzhora.es/sites/tarifaluzhora.es/files/styles/article_hero/public/pvpc-endesa-825x293.png.webp" alt="Tarifa PVPC"/>

                        <h2>PVPC en 2024: ¿Cómo será ahora la tarifa regulada de luz?</h2>
                        <p>A la lista de medidas aplicadas para evitar el aumento de las facturas de luz se une la nueva metodología de cálculo de la PVPC, con la que se pretende estabilizar el precio de la luz para los consumidores que se encuentran en el mercado regulado.</p>
                        <p>Se trata de una medida - recogida en el <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2022-7843&p=20230329&tn=1">Real Decreto Ley 10/2022</a> - para reducir los costes máximos y mínimos que resultan del mercado energético a diario para evitar tanta volatilidad en las facturas. De la misma forma, se pretende reducir la dependencia del mercado diario para la constitución del precio del kWh y pasar, por tanto, a anticipar dicho coste en determinados plazos (mensual, trimestral y anual) a través de los llamados <a href="/blog/mercados-de-futuros">mercados de futuro</a>.</p>
                        <p>Los requisitos para acceder a la tarifa regulada de luz no cambian para los consumidores domésticos, que mientras dispongan de una potencia contratada de hasta 10 kW podrán seguir optando ella. En este aspecto, otra novedad de esta medida radica en la posibilidad de que las microempresas que no superen esa barrera de 10 kW también puedan contratar la PVPC.</p>

                        <h2>¿Cómo se calculará el precio de la PVPC ahora?</h2>
                        <p>Si bien el precio de una factura de mercado regulado se definía a partir de una determinado día, causando fuertes variaciones entre meses, ahora se definirá en base a tres referencias temporales:</p>
                        <ul>
                            <li><strong>Mensual:</strong> puede llegar a representar el 10% del precio del mercado de futuros.</li>
                            <li><strong>Trimestral:</strong> puede llegar a representar el 36% del precio del mercado de futuros.</li>
                            <li><strong>Anual:</strong> puede llegar a representar el 54% del precio del mercado de futuros.</li>
                        </ul>
                        <p>A pesar de que la estimación anual tendrá más peso a la hora de definir el precio de la PVPC, se priorizarán los periodos más estables para evitar que el coste de las facturas se dispare de un mes a otro como sucede en la actualidad.</p>
                        <p>La implementación del mercado de futuros tendrá lugar a lo largo de 2023. El primer porcentaje de precio fijado irá en aumento hasta culminar en 2025 representando un 55% del peso final de la tarifa PVPC, el otro 45% seguirá dependiendo el mercado diario.</p>
                        <table>
                            <caption>Mercado En Los Ultimos Años</caption>
                            <tr>
                                <th></th>
                                <th>2023</th>
                                <th>2024</th>
                                <th>2025</th>
                            </tr>
                            <tr>
                                <td>Diario</td>
                                <td>75%</td>
                                <td>60%</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>A plazos</td>
                                <td>25%</td>
                                <td>40%</td>
                                <td>55%</td>
                            </tr>
                        </table>

                        <h2>¿Bajarán las facturas del PVPC?</h2>
                        <p>Esa reducción del mercado entre los precios máximos y mínimos se materializará en las siguientes cifras: pasar del 27% al 17% en la media diaria y del 26% al 16% en la mensual. Por tanto, para determinar si la nueva PVPC va a ayudar a ahorrar en las facturas, antes debemos definir los dos posibles escenarios que podrían producirse:</p>
                        <ul>
                            <li><strong>Mercado a la baja:</strong> en este caso, la nueva medida contendrá el precio para que la factura no se encarezca demasiado.</li>
                            <li><strong>Mercado al alza:</strong> igual que la nueva PVPC no permitirá que la luz se dispare a registros muy elevados como está sucediendo desde hace unos meses, tampoco va a permitir bajar el coste de la electricidad a los mínimos vividos en 2020, cuando la tarifa regulada de luz era la más barata.</li>
                        </ul>

                        <h2>¿Cuál es el precio de la tarifa PVPC?</h2>
                        <p>El precio del kWh en la PVPC se determina dependiendo de la oferta y de la demanda que haya en el mercado eléctrico. A dichos importes se les sumarán los costes regulados, fijados por el Gobierno, y un pequeño margen de beneficio destinado a la comercializadora con la que se realice el contrato.</p>
                        <p>Otras características importantes de la PVPC que deben conocer los consumidores son:</p>
                        <ul>
                            <li>El PVPC solo puede ser comercializado por las denominadas <a href="/blog/comercializadoras-referencia">comercializadoras de referencia</a>.</li>
                            <li>Es la única tarifa con la que puede solicitarse el <a href="/blog/factura-bono-social">bono social de luz</a>.</li>
                            <li>Solo disponible para suministros con una potencia contratada de hasta 10 kW.</li>
                        </ul>
                        <h3><a href="/">Consulta el precio del kWh hoy</a></h3>
                        <h3><a href="/luz-manana">Consulta el precio de la luz mañana</a></h3>


                        <h2>¿Cuáles son las modalidades del PVPC?</h2>
                        <p>Hasta junio de 2021, la tarifa PVPC podía contratarse con uno, dos o tres periodos de facturación. Tras la entrada en vigor de la nueva tarifa de luz, los periodos horarios pasan a ser tres en consumo y dos en potencia, siendo la discriminación horaria obligatoria.</p>

                        <h2>¿Cómo es la tarifa PVPC con discriminación horaria?</h2>
                        <p>La nueva tarifa PVPC con discriminación horaria clasifica los precios de la luz en tres periodos: punta, llano y valle. A su vez, la potencia contratada pasa de uno a dos periodos de facturación.</p>
                        <p>El cambio de precio entre una franja y otra se debe al peaje de acceso aplicado a cada periodo. En las horas valle, el peaje de acceso es considerablemente inferior al de las horas punta, abarantando así el precio a pagar por el kWh.</p>
                        <p>¿Qué son los <a href="/blog/peajes-de-acceso">peajes de acceso</a>? Los peajes de acceso son cuantías que sirven para cubrir los costes generados por el sistema eléctrico para proporcionar electricidad a los consumidores. Gravan consumo y potencia y variarán en función de la franja horaria.</p>
                        <p>La PVPC con discriminación horaria facilita el ahorro a aquellos consumidores que concentren su consumo durante la noche (de 00h a 08h) y fines de semana.</p>

                        <h2>Alternativas al PVPC en el mercado libre</h2>
                        <p>A diferencia de la PVPC, las tarifas del mercado libre están disponibles para todo tipo de potencias. Asimismo, disponen de una gran variedad de ofertas: con precio estable, discriminación horaria, tarifas de luz por horas, cuotas fijas, planas y tarifas personalizadas.</p>
                        <p>Te mostramos las mejores ofertas del mercado libre, ¿qué necesitas?</p>
                        <ul>
                            <li><a href="/blog/factura-luz-horas">Tarifas de luz por horas</a></li>
                            <li><a href="/blog/factura-precio-variable">Tarifas con precio estable</a></li>
                            <li><a href="/blog/factura-bono-social">Tarifas con Bono Social</a></li>
                            <li><a href="/blog/factura-luz-horas">Tarifa de luz por horas (indexada)</a></li>
                        </ul>

                        <h2>Tarifas de luz por horas</h2>
                        <p>Las tarifas de luz por horas, también conocidas como indexadas, se caracterizan por:</p>
                        <ul>
                            <li>Garantizar la energía a precio de mercado. Esto quiere decir que los usuarios pagarán por la luz el mismo precio que pague la comercializadora por ella en el mercado eléctrico.</li>
                            <li>Ofrecer un precio de potencia muy ajustado, en ocasiones, incluso menor que el de la PVPC.</li>
                            <li>Presentar los márgenes de comercialización, generalmente, en forma de cuota mensual o cuota sobre consumo.</li>
                        </ul>
                        <div>Las tarifas con precio estable del mercado libre son ideales para quienes no quieren preocuparse por la hora del consumo, ya que mantienen un precio fijo las 24 horas del día.</div>

                        <h2>Tarifas con discriminación horaria</h2>

                        <p>Las tarifas de precio fijo con discriminación horaria en tres periodos facturan tres precios de la energía: punta, llano y valle. Estas tarifas benefician a quienes concentran su consumo durante las horas valle.</p>

                        <h2>¿Cuáles son los requisitos para contratar la PVPC?</h2>

                        <p>Para contratar la Tarifa de Precio Voluntario al Pequeño Consumidor, es necesario cumplir con los siguientes requisitos:</p>

                        <ul>
                            <li>Suministro con potencia de hasta 10 kW.</li>
                            <li>Instalación eléctrica de baja tensión.</li>
                            <li>Contador de luz inteligente.</li>
                        </ul>

                        <h2>¿Cómo contratar la Tarifa PVPC?</h2>

                        <p>Los usuarios que cumplan los requisitos para contratar la PVPC deben contactar con la <a href="/blog/comercializadoras-referencia">Comercializadora de Referencia</a> de su elección y proporcionar la siguiente documentación:</p>

                        <ol>
                            <li>DNI y nombre completo del titular del contrato.</li>
                            <li>Dirección exacta del punto de suministro.</li>
                            <li>Código CUPS.</li>
                            <li>Teléfono de contacto.</li>
                            <li>Correo electrónico.</li>
                            <li>Cuenta bancaria.</li>
                        </ol>

                        <p>En caso de hacer uso del punto de suministro sin ser responsable del mismo, se recomienda realizar un cambio de titular de luz.</p>

                        <h2>¿Con qué Comercializadoras se puede contratar la PVPC?</h2>

                        <p>A diferencia del mercado libre, donde hay muchas opciones, en España solo existen 8 <a href="/blog/comercializadoras-referencia">Comercializadoras de Referencia</a> con las que se puede contratar la PVPC. Algunas de ellas son Energía XXI, Curenergía, Gas & Power, Régsiti, Baser, CHC COR, Teramelcor y Energía Ceuta XXI.</p>

                        <h2>¿Cuánto cuesta contratar PVPC?</h2>

                        <p>Contratar la Tarifa PVPC es gratuito, pero es importante considerar ciertas situaciones relacionadas con el suministro que podrían dificultar la gestión o requerir un desembolso económico.</p>

                        <div>Las personas con suministro eléctrico existente deben comprobar que las facturas estén al corriente de pago y que no haya compromiso de permanencia en el contrato actual.</div>

                        <p>Las personas sin suministro deben abonar en la factura de luz los derechos de extensión, acceso y enganche, costos regulados destinados a la distribuidora que realiza el alta.</p>

                        <h2>¿Cuánto tardan en poner PVPC?</h2>

                        <p>El plazo para contar con la Tarifa PVPC depende de si se trata de un cambio de compañía o de un alta de luz. En un cambio de comercializadora, la gestión se realiza en un periodo de entre 1 y 20 días hábiles sin cortar el suministro eléctrico. En un alta de luz, el plazo será de entre 1 y 7 días hábiles.</p>

                    </div>
                    <FooterBlog />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TarifaPVPC;
