const totalArticles = [
    { nombre: "¿Qué es el KiloWatio/Hora (Kwh) y como afecta a mi factura de la luz?", link: "/blog/que-es-el-kwh" },
    { nombre: "Cómo Entender Tu Factura de la Luz en 3 Sencillos Pasos", link: "/blog/entender-factura-luz" },
    { nombre: "Descubre el Misterio del Impuesto sobre la Electricidad", link: "/blog/impuesto-electrico" },
    { nombre: "Explorando las Diferentes Facturas de Luz", link: "/blog/tipos-facturas-luz" },
    { nombre: "Factura de Precio Fijo: Estabilidad y Control en tu Consumo de Energía", link: "/blog/factura-precio-fijo" },
    { nombre: "Factura de Luz por Horas: Eficiencia Energética y Ahorro", link: "/blog/factura-luz-horas" },
    { nombre: "Factura de Precio Variable: Entendiendo las Dinámicas de Costo", link: "/blog/factura-precio-variable" },
    { nombre: "Factura con Bono Social: Un Respiro en tu Presupuesto EnergéticoD", link: "/blog/factura-bono-social" },
    { nombre: "Factura con Energía Renovable: Desglose y Ejemplos Prácticos", link: "/blog/factura-energia-renovable" },
    { nombre: "Tarifa PVPC: Precio Voluntario al Pequeño Consumidor", link: "/blog/tarifa-pvpc" },
    { nombre: "¿Qué son las Comercializadoras de Referencia?", link: "/blog/comercializadoras-referencia" },
    { nombre: "¿Qué son los Peajes de Acceso?", link: "/blog/peajes-de-acceso" },
    { nombre: "¿Qué son los Mercados de Futuro?", link: "/blog/mercados-de-futuros" },
    { nombre: "Gestión Eficiente del Consumo Eléctrico", link: "/blog/gestion-eficiente-consumo" },
    { nombre: "Optimización Energética: Eficiencia, Reducción, Energías Renovables y Certificaciones", link: "/blog/optimizacion-energetica" },
    { nombre: "Iluminación LED: Ahorro Energético y Eficiencia Lumínica", link: "/blog/iluminacion-led" },
    { nombre: "Ahorra en la Luz: Descubre la App 'Tu Luz' para Controlar tus Gastos Energéticos", link: "/blog/ahorra-en-la-luz" },
    { nombre: "Eficiencia Energética en el Hogar: Descifrando las Calificaciones de Electrodomésticos", link: "/blog/eficiencia-electrodomesticos" },
    { nombre: "Aislamiento Térmico: Reduciendo el Consumo Energético", link: "/blog/aislamiento-termico" },
    { nombre: "Energía solar: Un camino hacia un futuro sostenible", link: "/blog/energia-solar" },
    { nombre: "¿Cómo Ahorrar en tu Factura de Luz?", link: "/blog/como-ahorrar-factura-luz" },
    { nombre: "Los 5 mitos más comunes sobre la factura de la luz y cómo te afectan", link: "/blog/5-mitos-mas-comunes-en-el-consumo-electrico" },
    { nombre: "Coches eléctricos: Guía completa para entender el presente y el futuro de la movilidad", link: "/blog/coches-electricos" },
    { nombre: "Guía completa para elegir la mejor tarifa de luz para tu hogar", link: "/blog/guia-completa-elegir-tarifa-luz" },
    { nombre: "Estrategias para Mitigar el Impacto Económico de los Picos de Precio de la Luz", link: "/blog/estrategias-para-mitigar-el-impacto-economico-de-los-picos-de-precio-de-la-luz" },
    { nombre: "Negociando Mejores Condiciones con tu Proveedor de Energía Eléctrica: Consejos Prácticos", link: "/blog/negociando-con-tu-proveedor-de-energia" },
    { nombre: "El Impacto de la Demanda Estacional en los Precios de la Luz: Una Mirada Profunda", link: "/blog/impacto-de-la-demanda-estacional" },
    { nombre: "Cómo calcular el ahorro real al optar por el PVPC en tu factura de luz", link: "/blog/calcular-ahorro-real" },
    { nombre: "Cómo planificar tus tareas diarias según el precio de la electricidad por hora", link: "/blog/planifica-tus-tareas-diarias-segun-precio-luz" },
    { nombre: "El impacto del PVPC en la sostenibilidad ambiental y la reducción de emisiones", link: "/blog/impacto-pvpc-en-la-sostenibilidad-ambiental" },
    

];

export default totalArticles;