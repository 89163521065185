import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/Contact.css";
import { Helmet } from "react-helmet";

function Contact() {

    const sendEmail = (event) => {
        event.preventDefault();
        alert("Mensaje enviado correctamente. Muchas gracias por contactar con nosotros.");
        document.getElementById("form_contact").reset();
    }

    const title = "Contacta con LuzPorHoras.es - Tu Socio en Ahorro de Energía";
    const description = "¿Tienes preguntas sobre precios de la luz o formas de ahorrar en tu factura eléctrica? Estamos aquí para ayudarte. Completa nuestro formulario de contacto, y nos pondremos en contacto contigo lo antes posible. LuzPorHoras.es, tu socio en la revolución energética.";
    const keywords = "Contacto LuzPorHoras.es, preguntas sobre precios de la luz, ahorro de energía, formulario de contacto, ayuda en línea, formas de ahorrar en la factura de la luz, mejores tarifas de luz, Luz por Horas Hoy, Luz por Horas Mañana, contacto rápido, revolución energética, LuzPorHoras.es";

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <Header />
            <div className="contact_container">
                <h1>Contacto</h1>
                <p>¿Tienes alguna pregunta o comentario? ¡Estamos aquí para ayudarte! Completa el formulario a continuación y nos
                    pondremos en contacto contigo lo antes posible.</p>

                <form id="form_contact" action="#" method="post" onSubmit={sendEmail}>
                    <label for="nombre">Nombre:</label>
                    <input type="text" id="nombre" name="nombre" required />

                    <label for="correo">Correo Electrónico:</label>
                    <input type="email" id="correo" name="correo" required />

                    <label for="mensaje">Mensaje:</label>
                    <textarea id="mensaje" name="mensaje" rows="4" required></textarea>

                    <button type="submit">Enviar Mensaje</button>
                </form>
            </div>

            <Footer />
        </div>
    )

}

export default Contact;