import React, { useEffect, useState } from "react";
import "../styles/BestRange.css";

function BestRange({ priceData }) {

    const [bestRange, setBestRange] = useState([]);

    useEffect(() => {
        let bestRangeStart = 0;
        let minSum = Infinity;

        for (let i = 6; i <= priceData.length - 4; i++) {
            const sum = priceData[i].price + priceData[i + 1].price + priceData[i + 2].price + priceData[i + 3].price;
            if (sum < minSum) {
                minSum = sum;
                bestRangeStart = i;
            }
        }

        setBestRange(priceData.slice(bestRangeStart, bestRangeStart + 4));
    }, [priceData]);

    return (
        <div className="best_range_container">
            <h1>¿Sabías que...</h1>
            {bestRange.length === 4 && <h2>{String(bestRange[0].hour).padStart(2, '0') + ":00h"} - {String(bestRange[bestRange.length - 1].hour+1).padStart(2, '0') + ":00h"}</h2>}
            {bestRange.length === 4 && <h3>Es el mejor rango de 4 horas durante el día para gastar electricidad. Si quieres 
                ahorrar hasta un 50% en la factura de la luz aprovecha esta franja para hacer la comida, poner la lavadora, ...</h3>}
        </div>
    );
}

export default BestRange;