import React from "react";
import "../styles/Header.css";
import logo from "../img/Logo.png";
import { Analytics } from '@vercel/analytics/react';

function Header() {
    return (
        <div className="header">
            <Analytics/>

            <div className="logo">
                <a href="/"> <img src={logo} alt="Logo Web" /></a>
            </div>

            <div className="item_menu">
                <div className="dropdown">
                    <div className="dropbtn">Blog</div>
                    <div className="dropdown-content">
                        <div className="list_menu">
                            <a href="/blog">Todos nuestros artículos</a>
                            <a href="/blog/como-ahorrar-factura-luz">Ahorra en tu factura</a>
                            <a href="/blog/comercializadoras-referencia">¿Qué son las Comercializadoras de Referencia?</a>
                            <a href="/blog/factura-luz-horas">Factura de Luz por Horas</a>
                            <a href="/blog/entender-factura-luz">Cómo Entender Tu Factura de la Luz</a>
                            <a href="/blog/eficiencia-electrodomesticos">Eficiencia Energética en el Hogar</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="item_menu">
                <div className="dropdown">
                    <div className="dropbtn">Contacto</div>
                    <div className="dropdown-content">
                        <div className="list_menu">
                            <a href="/sobre-nosotros">¿Quienes somos?</a>
                            <a href="/contacto">Contacta ahora</a>
                            <a href="/faq">Preguntas frequentes</a>
                        </div>
                    </div>
                </div>
            </div>




            <div className="item_menu">
                <div className="dropdown">
                    <div className="dropbtn">Precios de la Luz</div>
                    <div className="dropdown-content">
                        <div className="list_menu">
                            <a href="/">Precio de la Luz Hoy</a>
                            <a href="/luz-manana">Precio de la Luz Mañana</a>
                            <a href="/blog/optimizacion-energetica">Optimización Energética</a>
                            <a href="/blog/tipos-facturas-luz">Explorando las Diferentes Facturas de Luz</a>
                            <a href="/blog/tarifa-pvpc">Tarifa PVPC</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="item_menu">
                <div className="dropdown">
                    <div className="dropbtn">Legal</div>
                    <div className="dropdown-content">
                        <div className="list_menu">
                            <a href="/politica-privacidad">Política de privacidad</a>
                            <a href="/politica-cookies">Política de cookies</a>
                            <a href="/aviso-legal">Aviso Legal</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header_right">
                <div className="dropdown">
                    <div className="dropbtn_hamb" />
                    <div className="dropdown-content hamb">
                        <div className="list_menu">
                            <h1 className="nav_title">Precios de la Luz</h1>
                            <a href="/">Precio de la Luz Hoy</a>
                            <a href="/luz-manana">Precio de la Luz Mañana</a>
                            <a href="/blog/optimizacion-energetica">Optimización Energética</a>
                            <a href="/blog/tipos-facturas-luz">Explorando las Diferentes Facturas de Luz</a>
                            <a href="/blog/tarifa-pvpc">Tarifa PVPC</a>
                        </div>

                        <div className="list_menu">
                            <h1 className="nav_title">Blog</h1>
                            <a href="/blog">Todos nuestros artículos</a>
                            <a href="/blog/como-ahorrar-factura-luz">Ahorra en tu factura</a>
                            <a href="/blog/comercializadoras-referencia">¿Qué son las Comercializadoras de Referencia?</a>
                            <a href="/blog/factura-luz-horas">Factura de Luz por Horas</a>
                            <a href="/blog/entender-factura-luz">Cómo Entender Tu Factura de la Luz</a>
                            <a href="/blog/eficiencia-electrodomesticos">Eficiencia Energética en el Hogar</a>
                        </div>
                        <div className="list_menu">
                            <h1 className="nav_title">Contacto</h1>
                            <a href="/sobre-nosotros">¿Quienes somos?</a>
                            <a href="/contacto">Contacta ahora</a>
                            <a href="/faq">Preguntas frequentes</a>
                        </div>
                        <div className="list_menu">
                            <h1 className="nav_title">Legal</h1>
                            <a href="/politica-privacidad">Política de privacidad</a>
                            <a href="/politica-cookies">Política de cookies</a>
                            <a href="/aviso-legal">Aviso Legal</a>
                        </div>


                    </div>
                </div>
            </div>


        </div>
    );
}

export default Header;