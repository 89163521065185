import React, { useState, useEffect } from "react";
import "../styles/ListPrice.css";
import { v4 as uuidv4 } from "uuid";
import moon from "../img/moon.svg";
import sun from "../img/sun.svg";
import sunrise from "../img/sunrise.svg";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import caution from "../img/caution.svg";
import save from "../img/save.svg";


function ListPrice({ priceData, setDay }) {
    const [listPrice, setListPrice] = useState([]);

    function convertHSLToRGB(percentage) {
        const h = (1 - percentage / 100) * 120;
        let s = 0.8; // Saturation is at maximum
        let l = 0.45; // Lightness is at middle point
        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;
        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);
        return (`rgb(${r}, ${g}, ${b})`);
    }

    function getColor(percentage) {
        const hue = (1 - percentage / 100) * 120;
        return `hsl(${hue}, 100%, 50%)`;
    }

    const setActualHourIcon = () => {
        const hour = new Date().getHours();
        // const hour = 21;
        if (hour < 4 || hour > 19) {
            return moon;
        } else if (hour > 4 && hour < 8) {
            return sunrise;
        } else {
            return sun;
        }
    };

    const getStyleItem = (infoHour) => {
        if (new Date().getHours() === infoHour.hour && setDay===0) {
            return {
                transform: "scale(1.1)",
                fontWeight: "600",
                background: "linear-gradient(to right, transparent, "+getColor(infoHour.percentage).replace(')', `, ${0.4})`).replace('rgb', 'rgba')+", transparent)"
            };
        }
    
    };

    
    const getStyleFontWeight = (infoHour) => {
        if (new Date().getHours() === infoHour.hour) {
            return {
                fontWeight: "600",
            };
        }
    };

    const getStyleFontWeightColour = (infoHour) => {
        if (new Date().getHours() === infoHour.hour) {
            return { 
                color: convertHSLToRGB(infoHour.percentage),
                fontWeight: "600"};
        }else{
            return { color: convertHSLToRGB(infoHour.percentage)};
        }
    };
    

useEffect(() => {
    const listPrice = priceData.map((data, index) => {
        return {
            key: uuidv4(),
            hour: data.hour,
            price: data.price,
            percentage: data.percentage,
        };
    });
    setListPrice(listPrice);
}, [priceData]);

return (
    <div className="list_price">
        <h1>Precios Por Horas en MW</h1>
        {listPrice.map((infoHour, index) => (
            <div className="item_list" key={infoHour.key} style={getStyleItem(infoHour)}>
                <div className="colour_hour_cotainer">
                    <div className="colour_info" style={{ backgroundColor: getColor(infoHour.percentage) }} />
                    <div className="hour_info" style={getStyleFontWeight(infoHour)}>{String(infoHour.hour).padStart(2, '0') + ":00h"}</div>
                </div>
                <div className="price_info_container">
                    <div className="price_info" style={getStyleFontWeightColour(infoHour)}>{infoHour.price} €/MWh</div>
                    {new Date().getHours() === infoHour.hour && infoHour.percentage < 99 && infoHour.percentage > 5 && setDay===0?
                        <Tooltip className="actual_hour" title="Hora Actual" position="top" animation="scale" theme="light">
                            <img className="actual_hour" src={setActualHourIcon()} alt="Icono Hora Actual" />
                        </Tooltip> : null}
                    {infoHour.percentage > 99 ?
                        <Tooltip className="actual_hour" title="Hora Más Cara" position="top" animation="scale" theme="light">
                            <img className="actual_hour" src={caution} alt="Icono Hora Actual" />
                        </Tooltip> : null}
                    {infoHour.percentage < 5 ?
                        <Tooltip className="actual_hour" title="Hora Más Barata" position="top" animation="scale" theme="light">
                            <img className="actual_hour" src={save} alt="Icono Hora Actual" />
                        </Tooltip> : null}
                </div>
            </div>
        ))}
    </div>
);
}

export default ListPrice;