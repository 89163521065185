import React from "react";
import MainBlog from "../components/MainBlog";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import "../styles/BlogFormat.css";
import FooterBlog from "../components/FooterBlog";
import { Helmet } from "react-helmet";

function AhorraEnLaLuz() {

    const title = "Ahorra en la Luz: Descubre la App 'Tu Luz' para Controlar tus Gastos Energéticos";
    const description = "Descubre cómo 'Tu Luz' te empodera para ahorrar en tu factura de electricidad. Consulta precios en tiempo real, programa alarmas estratégicas y recibe consejos personalizados. Descarga la aplicación ahora y ilumina tu vida sin gastar de más.";
    const keywords = "Ahorro en la Luz, Consulta de Precios de Electricidad, Tu Luz - App para Ahorrar Energía, Widget de Precio de la Luz, Programa Alarmas de Consumo, Precios del Día Siguiente, Consejos para Ahorrar en Electricidad, Eficiencia Energética en el Hogar, Control de Gastos Energéticos, Descarga Gratuita de Tu Luz App";
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <Header />
            <div className="main_container">
                <div className="main_content">
                    <div className="header_content">
                        <h1 className="header_title">Ahorra en la Luz: Descubre la App 'Tu Luz' para Controlar tus Gastos Energéticos</h1>
                        <MainBlog time={2} />
                    </div>
                    <div className="line_space" />
                    <div className="blog_content">

                        <p>En nuestra búsqueda constante por brindarte herramientas útiles, te presentamos con entusiasmo nuestra aplicación estrella: "Tu Luz". Esta aplicación revolucionaria te ofrece información en tiempo real sobre el precio de la luz, junto con funciones exclusivas para optimizar tu consumo y ahorrar en tu factura mensual.</p>

                        <h2>Descubre 'Tu Luz': Tu Aliada para el Ahorro Energético</h2>

                        <ol>
                            <li><strong>Consulta Precios en Tiempo Real:</strong> Con "Tu Luz", nunca estarás a oscuras respecto a los costos de la energía eléctrica. Obtén datos actualizados en todo momento sobre el precio de la luz en la hora actual.</li>

                            <li><strong>Widget en tu Pantalla Principal:</strong> ¡Accede a la información clave directamente desde tu pantalla principal! El widget integrado te proporciona una visualización rápida y cómoda de los precios de la luz.</li>

                            <li><strong>Programa Alarmas Estratégicas:</strong> Personaliza alertas para ser notificado en momentos cruciales del día, como la hora más cara o más barata, y recibe avisos sobre la disponibilidad de los precios del día siguiente.</li>

                            <li><strong>Anticipación con PMD:</strong> Gracias a la función de Precios del Día Siguiente (PMD), obtén una visión anticipada de los costos energéticos desde las 13:15h, dándote una ventaja única para planificar tu consumo.</li>

                            <li><strong>Consejos Personalizados:</strong> "Tu Luz" te orienta sobre las mejores y peores horas para consumir electricidad, ayudándote a tomar decisiones informadas para optimizar tus hábitos de consumo.</li>

                            <li><strong>Ahorro Garantizado:</strong> La aplicación está diseñada para ayudarte a ahorrar en tu factura a final de mes. Descubre cómo pequeñas acciones pueden marcar la diferencia en tus gastos energéticos.</li>

                            <li><strong>Información Clara y Gratuita:</strong> Accede a información detallada sobre el funcionamiento de la aplicación y los precios que te proporciona, todo de forma gratuita. Con "Tu Luz", pagar menos es posible y fácil.</li>
                        </ol>

                        <p>Con "Tu Luz - Ahorra en la Luz", empodérate para tomar el control de tus gastos energéticos. Descárgala hoy mismo desde la Play Store y da el primer paso hacia un hogar más eficiente y económico. ¡Ilumina tu vida sin gastar de más!</p>

                    </div>
                    <FooterBlog />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AhorraEnLaLuz;

