import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './main/components/Main';
import Manana from './manana/components/Manana';
import Blog from './Blog/components/Blog';
import QueEsElKwh from './Blog/blog_entries/QueEsElKwh';
import EntenderFacturaLuz from './Blog/blog_entries/EntenderFacturaLuz';
import ImpuestoElectrico from './Blog/blog_entries/ImpuestoElectrico';
import DiferentesFacturasLuz from './Blog/blog_entries/DifrentesFacturaLuz';
import FacturaPrecioFijo from './Blog/blog_entries/FacturaPrecioFijo';
import FacturaPorHoras from './Blog/blog_entries/FacturaPorHoras';
import FacturaPrecioVariable from './Blog/blog_entries/FacturaPrecioVariable';
import FacturaBonoSocial from './Blog/blog_entries/FacturaBonoSocial';
import FacturaEnergiaRenovable from './Blog/blog_entries/FacturaEnergiaRenovable';
import TarifaPVPC from './Blog/blog_entries/TarifaPVPC';
import ComercializadorasDeReferencia from './Blog/blog_entries/ComercializadorasDeReferencia';
import PeajesAcceso from './Blog/blog_entries/PeajesAcceso';
import MercadosFuturos from './Blog/blog_entries/MercadosFuturos';
import GestionEficienteConsumo from './Blog/blog_entries/GestionEficienteConsumo';
import OptimizacionEnergetica from './Blog/blog_entries/OptimizacionEnergetica';
import IluminacionLED from './Blog/blog_entries/IluminacionLED';
import AboutUs from './aboutUs/components/AboutUs';
import Contact from './contact/components/Contact';
import PoliticPriv from './Legal/components/PoliticPriv';
import PoliticCookies from './Legal/components/PoliticCookies';
import AvisoLegal from './Legal/components/AvisoLegal';
import AhorraEnLaLuz from './Blog/blog_entries/AhorraEnLaLuz';
import CalificacionElectrodocmesticos from './Blog/blog_entries/CalificacionElectrodomesticos';
import AislamientoTermico from './Blog/blog_entries/AislamientoTermico';
import EnergiaSolar from './Blog/blog_entries/EnergiaSolar';
import ComoAhorrarFacturaLuz from './Blog/blog_entries/ComoAhorrarFacturaLuz';
import ReactGA from 'react-ga';
import MitosMasComunesFacturaLuz from './Blog/blog_entries/MitosMasComunesFacturaLuz';
import Error404 from './Error404/components/Error404';
import CochesElectricos from './Blog/blog_entries/CochesElectricos';
import GuiaCompletaElegirTarifaLuz from './Blog/blog_entries/GuiaCompletaElegirTarifaLuz';
import FAQ from './FAQ/components/FAQ';
import MitigarImpactoDeLosPicosDePrecio from './Blog/blog_entries/MitigarImpactoDeLosPicosDePrecio';
import NegociandoConTuProveedorDeEnergia from './Blog/blog_entries/NegociandoConTuProveedorDeEnergia';
import ImpactoDeLaDemandaEstacional from './Blog/blog_entries/ImpactoDeLaDemandaEstacional';
import CalcularAhorroReal from './Blog/blog_entries/CalcularAhorroReal';
import PlanificacionDiaria from './Blog/blog_entries/PlanificacionDiaria';
import ImpactoPVPCSostenibilidadAmbiental from './Blog/blog_entries/ImpactoPVPCSostenibilidadAmbiental';

ReactGA.initialize('G-ZT3DVZ0X26');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/luz-manana" element={<Manana />} />
        <Route path="/blog" element={<Blog />} />
        <Route path='/sobre-nosotros' element={<AboutUs />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path='/faq' element={<FAQ />} />


        <Route path="/politica-privacidad" element={<PoliticPriv />} />
        <Route path='/politica-cookies' element={<PoliticCookies />} />
        <Route path='/aviso-legal' element={<AvisoLegal />} />




        <Route path="/blog/que-es-el-kwh" element={<QueEsElKwh />} />
        <Route path='/blog/entender-factura-luz' element={<EntenderFacturaLuz />} />
        <Route path='/blog/impuesto-electrico' element={<ImpuestoElectrico />} />
        <Route path="/blog/tipos-facturas-luz" element={<DiferentesFacturasLuz />} />
        <Route path="/blog/factura-precio-fijo" element={<FacturaPrecioFijo />} />
        <Route path="/blog/factura-luz-horas" element={<FacturaPorHoras />} />
        <Route path="/blog/factura-precio-variable" element={<FacturaPrecioVariable />} />
        <Route path="/blog/factura-bono-social" element={<FacturaBonoSocial />} />
        <Route path="/blog/factura-energia-renovable" element={<FacturaEnergiaRenovable />} />
        <Route path="/blog/tarifa-pvpc" element={<TarifaPVPC />} />
        <Route path="/blog/comercializadoras-referencia" element={<ComercializadorasDeReferencia />} />
        <Route path="/blog/peajes-de-acceso" element={<PeajesAcceso />} />
        <Route path='/blog/mercados-de-futuros' element={<MercadosFuturos />} />
        <Route path='/blog/gestion-eficiente-consumo' element={<GestionEficienteConsumo />} />
        <Route path='/blog/optimizacion-energetica' element={<OptimizacionEnergetica />} />
        <Route path="/blog/iluminacion-led" element={<IluminacionLED />} />
        <Route path='/blog/ahorra-en-la-luz' element={<AhorraEnLaLuz />} />
        <Route path='/blog/eficiencia-electrodomesticos' element={<CalificacionElectrodocmesticos />} />
        <Route path="/blog/aislamiento-termico" element={<AislamientoTermico />} />
        <Route path="/blog/energia-solar" element={<EnergiaSolar />} />
        <Route path='/blog/como-ahorrar-factura-luz' element={<ComoAhorrarFacturaLuz />} />
        <Route path="/blog/5-mitos-mas-comunes-en-el-consumo-electrico" element={<MitosMasComunesFacturaLuz />} />
        <Route path='/blog/coches-electricos' element={<CochesElectricos />} />
        <Route path='/blog/guia-completa-elegir-tarifa-luz' element={<GuiaCompletaElegirTarifaLuz />} />
        <Route path='/blog/estrategias-para-mitigar-el-impacto-economico-de-los-picos-de-precio-de-la-luz' element={<MitigarImpactoDeLosPicosDePrecio />} />
        <Route path='/blog/negociando-con-tu-proveedor-de-energia' element={<NegociandoConTuProveedorDeEnergia />} />
        <Route path="/blog/impacto-de-la-demanda-estacional" element={<ImpactoDeLaDemandaEstacional />} />
        <Route path="/blog/calcular-ahorro-real" element={<CalcularAhorroReal />} />
        <Route path='/blog/planifica-tus-tareas-diarias-segun-precio-luz' element={<PlanificacionDiaria />} />
        <Route path='/blog/impacto-pvpc-en-la-sostenibilidad-ambiental' element={<ImpactoPVPCSostenibilidadAmbiental />} />
        
        <Route path='/*' element={<Error404 />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
