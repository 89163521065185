import React from "react";
import "../styles/Footer.css";
import PopUp from "./PopUp";
import CookieConsent from "react-cookie-consent";
// import ReviewForm from "../../Reviews/components/ReviewForm";
// import ReviewsJSON from "../../Reviews/XML/Reviews.json";
// import ReactGA from 'react-ga';

function Footer() {

    const style_cookies = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        // backgroundColor:"var(--primary-color)",
        margin: "10px",
        width: "98%",
        left: "0",
        borderRadius: "20px",
        fontWeight: "600",
    };

    const button_select_file = {
        display: 'inline-block',
        padding: '10px 20px',
        backgroundColor: 'var(--primary-color)', // Use camelCase for CSS properties in JavaScript
        color: '#fff',
        // border: '2px solid var(--button-color)',
        borderRadius: '15px', // Use camelCase for CSS properties in JavaScript
        textDecoration: 'none', // Use camelCase for CSS properties in JavaScript
        fontSize: 'large', // Use camelCase for CSS properties in JavaScript
        fontWeight: 'bold', // Use camelCase for CSS properties in JavaScript
        transition: 'background-color 0.5s, color 0.5s, transform 0.5s', // Use camelCase for CSS properties in JavaScript
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)', // Use camelCase for CSS properties in JavaScript
        cursor: 'pointer',
    };

    delete style_cookies.left;

    // const aceptarCookies = () => {
    //     ReactGA('consent', 'update', {
    //         'ad_user_data': 'granted',
    //         'ad_personalization': 'granted',
    //         'ad_storage': 'granted',
    //         'analytics_storage': 'granted'
    //     });
    // };


    const getYear = () => {
        const today = new Date();
        today.setDate(today.getDate());
        const year = today.getFullYear();

        return `${year}`;
    }

    return (
        <div className="footer">
            <PopUp />
            <div className="footer_north">
                <h2 className="h2_footer">Contacta con nosotros</h2>
                <a href="/contacto"><button>Contacta ahora</button></a>
            </div>
            <div className="footer_center">
                <div className="footer_center_dispo">
                    <h2 className="h2_footer">Sobre Nosotros</h2>
                    <a className="a_footer" href="/sobre-nosotros">¿Quiénes somos?</a>
                    <a className="a_footer" href="/contacto">Contacta ahora</a>
                    <a className="a_footer" href="/faq">Preguntas frequentes</a>
                </div>
                <div className="footer_center_dispo">
                    <h2 className="h2_footer">Blog</h2>
                    <a className="a_footer" href="/blog">Todos nuestros Artículos</a>
                    <a className="a_footer" href="/blog/como-ahorrar-factura-luz">Ahorra en tu factura</a>
                    <a className="a_footer" href="/blog/comercializadoras-referencia">¿Qué son las Comercializadoras de Referencia?</a>
                    <a className="a_footer" href="/blog/factura-luz-horas">Factura de Luz por Horas</a>
                    <a className="a_footer" href="/blog/entender-factura-luz">Cómo Entender Tu Factura de la Luz</a>
                    <a className="a_footer" href="/blog/eficiencia-electrodomesticos">Eficiencia Energética en el Hogar</a>
                </div>
                <div className="footer_center_dispo">
                    <h2 className="h2_footer">Precios Luz</h2>
                    <a className="a_footer" href="/">Luz por Horas Hoy</a>
                    <a className="a_footer" href="/luz-manana">Luz por Horas Mañana</a>
                    <a className="a_footer" href="/blog/optimizacion-energetica">Optimización Energética</a>
                    <a className="a_footer" href="/blog/tipos-facturas-luz">Explorando las Diferentes Facturas de Luz</a>
                    <a className="a_footer" href="/blog/tarifa-pvpc">Tarifa PVPC</a>
                
                </div>
                <div className="footer_center_dispo">
                    <h2 className="h2_footer">Legal</h2>
                    <a className="a_footer" href="/politica-cookies">Política de Cookies</a>
                    <a className="a_footer" href="/politica-privacidad">Política de Privacidad</a>
                    <a className="a_footer" href="/aviso-legal">Aviso Legal</a>
                </div>
            </div>
            <div className="footer_south">
                <p className="p_footer">© {getYear()} LuzPorHoras.es - Todos los derechos reservados.</p>
            </div>

            <CookieConsent
                location="bottom"
                buttonText="¡Entiendo!"
                cookieName="myCookieConsent"
                className="cookie_consent"
                expires={365}
                style={style_cookies}
                buttonStyle={button_select_file}
            >
                Utilizamos cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web. Al continuar, aceptas nuestro uso de cookies. Obtenga más información en nuestro <a href="/politica-cookies" color="var(--primary-color)" >Política de Cookies</a>.
            </CookieConsent>

        </div>
    );
}

export default Footer;