import React from "react";
import Header from "../../Header/components/Header";
import Footer from "../../Footer/components/Footer";
import PrecioLuzActual from "./PrecioLuzActual";
import { Helmet } from 'react-helmet';


function Main() {

    const getTodayTitle = () => {
        const today = new Date();
        today.setDate(today.getDate()); // Add one day to today
        const year = today.getFullYear();
        const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const month = monthNames[today.getMonth()];
        const day = today.getDate().toString().padStart(2, '0');
    
        return `${day} ${month} ${year}`;
    }

    const title = "Consulta el Precio de la Luz de HOY | "+getTodayTitle()+" ✅";
    const description = "Descubre el precio actualizado de la luz en España. Analiza la evolución de la tarifa PVPC, con detalles por horas y consejos para ahorrar en tu factura eléctrica.";
    const keywords = "precio de la luz, tarifa PVPC, evolución de precios, consejos de ahorro energético, hora más barata del día, precios por horas, ahorro en la factura de la luz, Precio Voluntario para el Pequeño Consumidor, PVPC, cómo se calcula el PVPC, consejos de eficiencia energética, Red Eléctrica de España, ahorro de energía, mejores tarifas de luz, actualización de precios, mercado eléctrico,  datos verificados, transparencia en tarifas eléctricas, LuzPorHoras.es";
    
    return (
        <div>
            
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content="Saúl Del Rosario Sosa" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta property="og:url" content="https://luzporhoras.es" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://s1.eestatic.com/2022/03/17/sociedad/consumo/657944297_222759406_1706x960.jpg" />
                <meta name="twitter:card" content="summary_large_image" />

            </Helmet>
            <Header />
            <PrecioLuzActual />
            <Footer />
        </div>
    );
}

export default Main;